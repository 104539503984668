import React, { useContext, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';

import { UserContext } from 'eficia/contexts/UserProvider';

import { Footer } from '../Footer';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';

import {
  AppContent,
  AppContentInner,
  AppContentWrapper,
  AppMain,
  AppWrapper
} from './LeftSidebar.style';

function LeftSidebar({ children }) {
  const withoutSidebar = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [sidebarToggleMobile, setSidebarToggleMobile] = useState(false);

  const { changeUserFilters } = useContext(UserContext);

  // Generateur de key utilisé au besoin pour reset des components :
  // - Au chargement de la page
  // - Après un changement d'organisation
  const [keygen, setKeygen] = useState(Math.random());
  useEffect(() => {
    // On force le reset des components
    setKeygen(Math.random());

    // On reset aussi les filtres utilisateurs
    changeUserFilters({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppWrapper>
      <Header
        sidebarToggleMobile={sidebarToggleMobile}
        setSidebarToggleMobile={setSidebarToggleMobile}
      />
      <AppMain>
        <Sidebar
          sidebarToggleMobile={sidebarToggleMobile}
          setSidebarToggleMobile={setSidebarToggleMobile}
        />
        <AppContent>
          <AppContentInner>
            <AppContentWrapper style={{ marginLeft: withoutSidebar ? 0 : 264 }}>
              {children}
            </AppContentWrapper>
          </AppContentInner>
          <Footer />
        </AppContent>
      </AppMain>
    </AppWrapper>
  );
}

LeftSidebar.propTypes = {
  children: PropTypes.node
};

export default LeftSidebar;
