import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

import { redirectLoggedUser } from '../utils/loaders';
import { LOGIN_PATH } from '../paths';

export const homepageRoutes = [
  {
    path: LOGIN_PATH,
    loader: redirectLoggedUser,
    lazy: () => lazyWithRetry(() => import('eficia/views/login/LoginPage'))
  }
];
