import React from 'react';

import { Icon } from 'eficia/components/atoms/Icon';
import {
  CONFIGURATION_HOME_PATH,
  GAS_METERS_HOME_PATH,
  GROUPS_HOME_PATH,
  ORGANIZATIONS_HOME_PATH,
  PERSONALIZED_PAGES_HOME_PATH,
  SITES_HOME_PATH,
  TRANSLATION_HOME_PATH,
  USERS_HOME_PATH
} from 'eficia/routes/paths';

export const NAV_CONFIG = [
  {
    label: '',
    content: [
      {
        label: 'sidebar.menu.configuration.label',
        icon: <Icon name="configuration" />,
        slug: 'configuration',
        to: CONFIGURATION_HOME_PATH,
        content: [
          {
            label: 'menu.sidebar.sites.title',
            to: SITES_HOME_PATH,
            slug: 'configuration',
            icon: <Icon name="site" />
          },
          {
            label: 'menu.sidebar.groups.title',
            to: GROUPS_HOME_PATH,
            slug: 'configuration',
            icon: <Icon name="parc" />
          },
          {
            label: 'menu.sidebar.users.title',
            to: USERS_HOME_PATH,
            slug: 'configuration',
            icon: <Icon name="users" />
          },
          {
            label: 'menu.sidebar.organizations.title',
            to: ORGANIZATIONS_HOME_PATH,
            slug: 'configuration',
            icon: <Icon name="organization" />
          }
        ]
      },
      {
        label: 'menu.sidebar.gasmeters.title',
        to: GAS_METERS_HOME_PATH,
        slug: 'gasmeters',
        icon: <Icon name="gas" />
      },
      {
        label: 'menu.sidebar.translation.title',
        to: TRANSLATION_HOME_PATH,
        slug: 'translation',
        icon: <Icon name="translation" />
      },
      {
        label: 'menu.sidebar.personalized_pages.title',
        to: PERSONALIZED_PAGES_HOME_PATH,
        slug: 'personalized_pages',
        icon: <Icon name="group" />
      }
    ]
  }
];
