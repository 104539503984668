import React from 'react';
import { CookiesProvider } from 'react-cookie';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as am5 from '@amcharts/amcharts5';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import { AnimatePresence } from 'framer-motion';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'styled-components';

import { Notification } from 'eficia/components/molecules/Notification';
import { LayoutProvider } from 'eficia/contexts/LayoutProvider';
import { NotificationProvider } from 'eficia/contexts/NotificationProvider';
import { TranslationProvider } from 'eficia/contexts/TranslationProvider';
import Router from 'eficia/routes/Router';

import '../../../assets/base.scss';
import MuiTheme from '../../../theme';

library.add(fas, far);

export default function App() {
  // init config react-query
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  // eslint-disable-next-line react/no-unstable-nested-components
  const forwardNotification = React.forwardRef((props, ref) => {
    return <Notification ref={ref} notification={props} />;
  });

  // amCharts 5
  am5.addLicense('AM5C345010209');

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={MuiTheme}>
            <ThemeProvider theme={MuiTheme}>
              <CssBaseline />
              <LayoutProvider>
                <AnimatePresence>
                  <TranslationProvider>
                    <SnackbarProvider
                      Components={{
                        custom: forwardNotification
                      }}
                      maxSnack={5}
                      preventDuplicate
                    >
                      <NotificationProvider>
                        <Router />
                      </NotificationProvider>
                    </SnackbarProvider>
                  </TranslationProvider>
                </AnimatePresence>
              </LayoutProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </CookiesProvider>
    </QueryClientProvider>
  );
}
