import axiosConfig from './config/axiosConfig';
import { USER_API } from './config/constants';

export default function usersApi() {
  return {
    // async getXsrfToken() {
    //   const query = await axiosConfig.get(
    //     `${EFICIA_BASE}/sanctum/csrf-cookie`
    //     // {
    //     //   headers: {
    //     //     referer: 'https://staging.adminpanel.ws.eficia.com'
    //     //     // 'Access-Control-Allow-Origin': '*'
    //     //   }
    //     // }
    //   );

    //   return query;
    // },

    async login(params) {
      const query = await axiosConfig.post(`${USER_API}/login`, params);

      return query;
    },
    async isConnected() {
      const query = await axiosConfig.post(`${USER_API}/is-connected`);

      return query;
    }
  };
}
