import React, { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import PulseModal from 'eficia/components/organisms/Modal/PulseModal';
import { LocationProvider } from 'eficia/contexts/LocationProvider';
import { UserProvider } from 'eficia/contexts/UserProvider';
import { LeftSidebar } from 'eficia/views/template/LeftSideBar';

import { LOGIN_PATH } from './paths';

export function Layout() {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  if (LOGIN_PATH === location.pathname) {
    return (
      <Suspense fallback={<PulseModal />}>
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          <Outlet />
        </motion.div>
      </Suspense>
    );
  }

  return (
    <UserProvider>
      <LocationProvider>
        <LeftSidebar>
          <Suspense fallback={<PulseModal />}>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
            >
              <Outlet />
            </motion.div>
          </Suspense>
        </LeftSidebar>
      </LocationProvider>
    </UserProvider>
  );
}
