import styled from 'styled-components';
import { InputBase } from '@mui/material';

export const Input = styled(InputBase)`
  color: #fff;

  img {
    border: 1px solid #f4f5fd;
    border-radius: 50px;
    color: #fff;
    width: 24px;
    height: 24px;
  }

  .MuiSelect-select:focus {
    background: transparent;
  }

  .MuiSelect-select.MuiSelect-select {
    padding-right: 0;
  }
  .MuiSelect-icon {
    display: none;
  }
`;

export const FlagContainer = styled.div`
  img {
    border: 1px solid #f4f5fd;
    border-radius: 50px;
    color: #fff;
    width: 24px;
    height: 24px;
  }
`;
