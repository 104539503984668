import axiosConfig from './config/axiosConfig';
import { USER_API } from './config/constants';

export default function usersApi() {
  return {
    async fetch(params) {
      // const config = {
      //   headers: {
      //     Authorization: `Bearer {"KEY":"${process.env.REACT_APP_TOKEN_ADMIN_BOARD}"}`
      //   }
      // };
      const query = await axiosConfig.post(
        `${USER_API}/get-user-list`,
        params
        // config
      );
      return query;

      // return {
      //   data: {
      //     data: [
      //       {
      //         id: '1',
      //         email: 'user1@gmail.com',
      //         name: 'Vandini',
      //         firstname: 'Jeremy',
      //         createdAt: '12-11-2020',
      //         organizations: [
      //           {
      //             organization: 'test',
      //             organizationId: '1',
      //             roleId: '1',
      //             role: 'Editor',
      //             isAdmin: true
      //           }
      //         ]
      //       }
      //     ],
      //     meta: {
      //       pagination: {
      //         currentPage: 1,
      //         lastPage: 1,
      //         perPage: 50,
      //         itemCount: 1
      //       }
      //     }
      //   }
      // };
    },
    // async create(params) {
    //   const query = await axiosConfig.post(
    //     `${USER_API}/invite-to-organization`,
    //     params
    //   );
    //   return query;
    // },
    // async edit(params) {
    //   const query = await axiosConfig.post(`${USER_API}/edit-user`, params);
    //   return query;
    // },
    async delete(params) {
      const query = await axiosConfig.post(`${USER_API}/delete-user`, params);
      return query;
    },
    async loginAs(params) {
      const query = await axiosConfig.post(`${USER_API}/login-as`, params);
      return query;
    }
  };
}
