// Ici sont définies les routes de l'application

// Login
// Page non connecté
export const LOGIN_PATH = '/';

// Accueil de l'admin
// Page connecté
export const LOGGED_PATH = '/users';

// Configuration
export const CONFIGURATION_HOME_PATH = '/users';
export const USERS_HOME_PATH = '/users';
export const SITES_HOME_PATH = '/sites';
export const SITES_DETAILS_PATH = '/sites/details/:siteId';
export const SITES_EDIT_PATH = '/sites/edit/:siteId';
export const ORGANIZATIONS_HOME_PATH = '/organizations';

// Groups
export const GROUPS_HOME_PATH = '/groups';

// Gas Meters
export const GAS_METERS_HOME_PATH = '/gasmeters';

// Translation
export const TRANSLATION_HOME_PATH = '/translation';

// Personnalized Pages
export const PERSONALIZED_PAGES_HOME_PATH = '/personalized_pages';
