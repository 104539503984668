import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  position: relative;
  .Mui-disabled {
    color: #a2a5a8;
    background: rgba(0, 0, 0, 0.03);
  }

  .Button-underline {
    & > * {
      text-decoration: underline;
      text-underline-offset: 10px;
    }
  }

  .Button-skip {
    color: #a2a5a8;
    background: rgba(0, 0, 0, 0.03);
  }
  .btn-full-width {
    width: 100%;
  }
  .Button-success {
    background: #32bea6;
    width: 250px;
    height: 50px;

    & > * {
      font-size: 18px;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    font-size: 14px !important;
  }

  .eficia-link-button:hover {
    background-color: rgba(255, 255, 255, 0.03);
  }

  .MuiButton-containedPrimary {
    color: white;
  }
  .Button-secondary {
    background-color: #cbd3dd;
    color: #224f7a;
  }

  .Mui-disabled {
    background-color: ${({ theme }) => theme.palette.grey[200]};

    color: ${({ theme }) => theme.palette.grey[400]};
  }
`;
