import React from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';

import { routes } from './routes';

// 2024-02-29: Nous continuons à utiliser `createHashRouter` (avec le `#` dans l'URL) pour éviter les erreurs 404 sur un accès direct
// TODO: À replacer par `createBrowserRouter` (sans le `#` dans l'URL) une fois que les 404 **côté serveur** sont redirigées vers `/`
const router = createHashRouter(routes);

export default function Router() {
  return <RouterProvider router={router} />;
}
