import { useCallback } from 'react';
import { useQuery } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';

export function useFetchTranslations({ defaultLocale, currentLocale }) {
  const client = eficiaClient();

  const fetcher = useCallback(() => {
    // Télécharger la langue par défaut (pour les fallbacks)
    const promises = [client.translation.fetch({ locale: defaultLocale })];
    if (currentLocale !== defaultLocale) {
      // Télécharger la langue courante
      promises.push(client.translation.fetch({ locale: currentLocale }));
    }

    // Tout en parallèle
    // Retourne un objet avec les traductions de la langue par défaut et de la langue courante
    return Promise.all(promises).then((results) => {
      const aggregated = {
        [defaultLocale]: results[0]?.data
      };
      if (results[1]) {
        aggregated[currentLocale] = results[1]?.data;
      }
      return aggregated;
    });
  }, [client.translation, defaultLocale, currentLocale]);

  const { data, error, isFetching } = useQuery(
    ['translations', defaultLocale, currentLocale],
    fetcher,
    {
      cacheTime: 0
    }
  );

  return {
    data,
    error,
    isFetching
  };
}
