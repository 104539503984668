import { AppBar } from '@mui/material';
import styled from 'styled-components';

export const AppHeader = styled(AppBar)`
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 263px;
  background-color: #fff;
  border: none;
  z-index: 1000;
`;

export const MenuButtonContainer = styled.div`
  color: #224F7A;
  padding-top: 20px;
  padding-left: 20px;
`;
