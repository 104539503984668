import axios from 'axios';
import axiosConfig from './config/axiosConfig';
import { WS_APP_ADMIN_PANEL_API } from './config/constants';

export default function translationApi() {
  return {
    async fetch({ locale }) {
      return axios.get(
        `${process.env.REACT_APP_S3_PUBLIC_BUCKET_URL}/translations/webapp/${
          locale.split('-')[0]
        }.json?${Date.now()}`,
        { withCredentials: false }
      );
    },

    async uploadTranslation(params) {
      const fd = new FormData();
      fd.append('translations', params.file);
      fd.append('target', params.target);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      const query = await axiosConfig.post(
        `${WS_APP_ADMIN_PANEL_API}/upload-translation-file`,
        fd,
        config
      );

      return query;
    }
  };
}
