import axiosConfig from './config/axiosConfig';
import { ORGANIZATIONS_API } from './config/constants';

export default function organizationsApi() {
  return {
    async fetch(params) {
      const query = await axiosConfig.post(
        `${ORGANIZATIONS_API}/get-organization-list`,
        params
        // config
      );
      return query;
    },
    async fetchFull(params) {
      const query = await axiosConfig.post(
        `${ORGANIZATIONS_API}/get-organization-full-list`,
        params
        // config
      );
      return query;
    },
    async fetchOne(id) {
      const query = await axiosConfig.post(`${ORGANIZATIONS_API}/get-organization`, id);
      return query;
    },
    async edit(params) {
      const query = await axiosConfig.post(`${ORGANIZATIONS_API}/edit-organization`, params);
      return query;
    }
  };
}
