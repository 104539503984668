export const EFICIA_BASE_API =
  window.location.hostname === 'localhost'
    ? `${process.env.REACT_APP_WS_APP_URL}/api`
    : `https://${window.location.hostname
        .replace('adminpanel.app', 'ws')
        .replace('.localhost', '')}/api`;
// auth / user

export const EFICIA_ADMIN_PANEL_BASE_API = `${EFICIA_BASE_API}/admin-panel`;

export const USER_API = `${EFICIA_ADMIN_PANEL_BASE_API}`;

// gasmeters
export const GAS_METERS_API = `${EFICIA_ADMIN_PANEL_BASE_API}`;

// sites
export const SITES_API = `${EFICIA_ADMIN_PANEL_BASE_API}`;

// organizations
export const ORGANIZATIONS_API = `${EFICIA_ADMIN_PANEL_BASE_API}`;

// groups
export const GROUPS_API = `${EFICIA_ADMIN_PANEL_BASE_API}`;

// translation
export const WS_APP_ADMIN_PANEL_API = `${EFICIA_ADMIN_PANEL_BASE_API}`;
