import userApi from 'eficia/services/user';

import { LOGGED_PATH } from '../paths';

// Redirige l'utilisateur connecté vers l'accueil de l'admin
// Ce loader est adapté à une page de type Signin / Signup / ...
export const redirectLoggedUser = async () => {
  try {
    const data = await userApi().isConnected();
    if (data?.data?.data?.isConnected) {
      window.location.href = `/#${LOGGED_PATH}`;
    }
    return null;
  } catch {
    // Si la requête échoue alors rentrer en mode dégradé et ainsi éviter une boucle de redirections
    return null;
  }
};
