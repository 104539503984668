import styled from 'styled-components';
import Button from '../Button';

export const FilterButtonUi = styled(Button)`
  background: transparent;
  color: #7b7c9a;

  &.isActive {
    background: #f4f5fe;
  }
  &:hover {
    background: #f4f5fe;
  }
  svg {
    font-size: 14px !important;
  }
`;
