import { stringifyQuery } from 'eficia/utilities/query';
import { isIOS } from 'eficia/utilities/platform';

export function redirectToPreviousPage(navigate, pathname, page) {
  navigate(
    `${pathname}${stringifyQuery({
      page: parseInt(page) <= 1 ? 1 : page - 1
    })}`
  );
}

export function handleExternalRedirection(url) {
  if (isIOS) {
    const linkElement = document.createElement('a');
    linkElement.id = 'link';
    window.document.body.appendChild(linkElement);
    const link = document.getElementById('link');
    link.setAttribute('href', url);
    link.click();
  } else {
    window.open(url, '_blank').focus();
  }
}
