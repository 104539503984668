import axiosConfig from './config/axiosConfig';
import { SITES_API } from './config/constants';

export default function personalizedPagesApi() {
  return {
    async fetchAccessFullList() {
      const query = await axiosConfig.post(
        `${SITES_API}/get-access-full-list`
      );
      return query;
    },
    async fetchPageList(params) {
      const query = await axiosConfig.post(
        `${SITES_API}/get-personalized-page-list`,
        params
      );
      return query;
    },
    async addPage(params) {
      const query = await axiosConfig.post(
        `${SITES_API}/add-personalized-page`,
        params
      );
      return query;
    },
    async editPage(params) {
      const query = await axiosConfig.post(
        `${SITES_API}/edit-personalized-page`,
        params
      );
      return query;
    },
    async deletePage(id) {
      const query = await axiosConfig.post(
        `${SITES_API}/delete-personalized-page`,
        { id }
      );
      return query;
    },
    async fetchWidgets(params) {
      const query = await axiosConfig.post(
        `${SITES_API}/get-personalized-page-widgets`,
        params
      );
      return query;
    },
    async addWidget(params) {
      const query = await axiosConfig.post(`${SITES_API}/add-widget`, params);
      return query;
    },
    async editWidget(params) {
      const query = await axiosConfig.post(`${SITES_API}/edit-widget`, params);
      return query;
    },
    async deleteWidget(id) {
      const query = await axiosConfig.post(`${SITES_API}/delete-widget`, {
        id
      });
      return query;
    },
    async fetchDataVisualizationToken(params) {
      const query = await axiosConfig.post(
        `${SITES_API}/get-data-visualization-token`,
        params
      );
      return query;
    }
  };
}
