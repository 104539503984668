import axiosConfig from './config/axiosConfig';
import { GAS_METERS_API } from './config/constants';

export default function gasMetersApi() {
  return {
    async fetch(params) {
      // const config = {
      //   headers: {
      //     Authorization: `Bearer {"KEY":"${process.env.REACT_APP_TOKEN_ADMIN_BOARD}"}`
      //   }
      // };
      const query = await axiosConfig.post(
        `${GAS_METERS_API}/get-gas-meter-list`,
        params
        // config
      );
      return query;
    }
    // async delete(params) {
    //   const query = await axiosConfig.post(`${GAS_METERS_API}/delete-gas-meter`, params);
    //   return query;
    // }
  };
}
