import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';

const NotificationContext = React.createContext();
const { Provider } = NotificationContext;

function NotificationProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  const [notification, setNotification] = useState(null);
  const addNotification = ({ title, message, type, duration = 5000, link }) => {
    enqueueSnackbar(message, {
      title,
      type,
      duration,
      link,
      autoHideDuration: duration,
      variant: 'custom'
    });
  };
  const removeNotification = () => setNotification(null);

  const contextValue = {
    notification,
    addNotification: useCallback((title, message, status, duration, link) => {
      addNotification(title, message, status, duration, link);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
    removeNotification: useCallback(() => removeNotification(), [])
  };

  return <Provider value={contextValue}>{children}</Provider>;
}

NotificationProvider.propTypes = {
  children: PropTypes.node
};

export { NotificationProvider, NotificationContext };
